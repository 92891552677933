<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆编号" prop="vehicleId">
        <a-select v-model="form.vehicleId" placeholder="请选择">
          <a-select-option v-for="(d, index) in vehiclesOptions" :key="index" :value="d.vehicleId">
            {{ d.vehicleId }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="选择能力" prop="dictValues">
        <a-select mode="multiple" v-model="form.dictValues" placeholder="请选择">
          <a-select-option v-for="(d, index) in abilityOptions" :key="index" :value="d.dictValue">
            {{ d.dictLabel }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleAbility, addVehicleAbility, updateVehicleAbility } from '@/api/iot/vehicleAbility'

export default {
  name: 'CreateForm',
  props: {
    abilityOptions: {
      type: Array,
      required: true
    }
  },
  components: {},
  data() {
    return {
      // 车辆选项
      vehiclesOptions: [],
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        vehicleId: null,
        dictValue: null,
        dictValues: [],
        remark: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
        dictValues: [{ required: true, message: '能力不能为空', trigger: 'blur' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: null,
        dictValue: null,
        dictValues: [],
        remark: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      getVehicleAbility(0).then(response => {
        this.vehiclesOptions = response.vehicles
        console.log('getVehicleAbility')
        this.form.vehicleId = row
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleAbility(id).then(response => {
        this.form = response.data
        this.dictValues = response.data.dictValues
        this.vehiclesOptions = response.vehicles
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        console.log('dictValues:' + this.dictValues)
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateVehicleAbility(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleAbility(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
