import request from '@/utils/request'

// 查询车辆能力关联列表
export function listVehicleAbility (query) {
  return request({
    url: '/iot/vehicleAbility/list',
    method: 'get',
    params: query
  })
}

// 查询车辆能力关联详细
export function getVehicleAbility (id) {
  return request({
    url: '/iot/vehicleAbility/' + id,
    method: 'get'
  })
}

// 新增车辆能力关联
export function addVehicleAbility (data) {
  return request({
    url: '/iot/vehicleAbility',
    method: 'post',
    data: data
  })
}

// 修改车辆能力关联
export function updateVehicleAbility (data) {
  return request({
    url: '/iot/vehicleAbility',
    method: 'put',
    data: data
  })
}

// 删除车辆能力关联
export function delVehicleAbility (id) {
  return request({
    url: '/iot/vehicleAbility/' + id,
    method: 'delete'
  })
}

// 导出车辆能力关联
export function exportVehicleAbility (query) {
  return request({
    url: '/iot/vehicleAbility/export',
    method: 'get',
    params: query
  })
}
